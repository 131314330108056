@import "../../node_modules/bootstrap/scss/_functions";
@import "theme/functions";

@import "../../node_modules/bootstrap/scss/mixins";
@import "theme/mixins";

@import "user-variables";
@import "theme/variables";
@import "../../node_modules/bootstrap/scss/variables";

// 
// 
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.image-wrapper {
    height: 100%;
}
.image-wrapper::after {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.1);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.card-img-overlay a.text-light {
    text-shadow: 4px 4px 2px black;
}

.icon-medium {
    width: 60px;
}

.icon-small {
    width: 30px;
}

.red-text {
    color: #ff0000;
}

.margin-b-20px {
    margin-bottom: 20px;
}

a {
    color: #000000;
    text-decoration: none;
}
a:hover {
    color: #ff0000;
    text-decoration: underline;
}

.w-45 {
    width: 45%;
}
.w-60 {
    width: 60%;
}
.w-100 {
    width: 100%;
}
.w-100-cta {
    width: 100%
}
.w-65vw {
    width: 60vw;
}
.fit-content {
    width: fit-content;
}
.border-rad-30 {
    border-radius: 30px;
}
.centered-list-column {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}
.pointer {
    cursor: pointer;
}

.image-shadow {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

button.accordion-button {
    font-size: 2rem;
    background-color: #fff0;
}

.accordion-body {
    font-size: 1.33rem;
}

.text-shadow {
    text-shadow: 4px 4px 2px black;
}
.text-black {
    color: #000;
}
.black-background {
    background-color: #000;
}

.career-text {
    margin: 2rem 0 2rem 0;
}
.career-text *{
    font-size: 1.5rem;
}

.fs-15 {
    font-size: 1.5rem;
}

.fs-all-15 *{
    font-size: 1.5rem;
}

ul.li-dash {
    list-style-type: none;
}
ul.li-dash li:before {
    content: '\2014';
    position: absolute;
    margin-left: -2rem;
}

.fw-800 {
    font-weight: 800;
}
.btn-close-sidebar {
    display: flex;
    justify-content: center;
    padding: 5px 0 0 0;
}
.home-instagram__icon.svg-inline--fa.fa-w-14 {
    width: 4rem;
}
.home-instagram__icon.svg-inline--fa {
    height: 4rem;
}
.go-home__button {
    width: 3rem;
}

@media screen and (min-width: 360px) {
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
    .w-100 {
        max-width: 95% !important;
    }
    .mobile-footer {
        margin-bottom: 0 !important;
    }
    .mobile-footer .row {
        flex-direction: column-reverse;
    }
    .card-body:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-body {
        padding-left: 0;
        padding-right: 0;
    }
    .accordion-body {
        padding: 0;
    }
    .accordion-button {
        padding-left: 0;
        padding-right: 0;
    }
    .title {
        font-size: 2rem;
    }
  }
@media screen and(max-width: 575.98px) {
    .row > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .row {
        margin-right: 0;
        margin-left: 0;
    }
    .w-75 {
        width: 100% !important;
    }
    .card-img-overlay {
        padding: 0 0 10px 10px !important;
    }
    .card-img-overlay.flex-center{
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: flex-start;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: flex-end;
    }
    .card-body {
        padding-bottom: 0 !important;
    }
    .padx-sm-3 {
        padding: 0 1rem 0 1rem !important;
    }
    div.contact-me__container p{ 
        font-size: 1rem !important;
    }
    .btn-close-boots-container {
        right: 2rem;
    }
    #bigpicture > div {
        width: 100%;
    }
    .accordion strong, .accordion p, .accordion-header, .accordion-body > h3{
        padding: 0 1rem 0 1rem !important;
    }
    .accordion ul {
        margin-left: 2.5rem;
        padding: 0 1rem 0 0 !important;
    }
    .accordion-item {
        border: 0 !important;
    }
    .accordion img {
        max-width: 95% !important;
    }
    #collapseThree iframe {
        height: 60vh;
    }
    ul.li-dash {
        margin-top: 0;
    }
    .fs-all-15 p, .fs-all-15 ul li, .fs-all-15 span{
        font-size: 1rem;
    }
    a.player img {
        width: 60px;
    }

    .order-1.w-45, .order-0.w-45 {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 !important;
        margin-bottom: 3rem;
    }
    .training_methodology p, .training_methodology h4 {
        padding: 0 1rem !important;
    }
    .training_methodology p, .training_methodology span {
        font-size: 1rem !important;
    }
    .mobile-hidden__true {
        display: none;
    }
    .methodology__picture img {
        width: 95% !important;
    }
    .program-options__list {
        align-items: flex-start;
        padding: 0 1rem !important;
        margin: 0 !important;
    }
    .program-options__list li {
        font-size: 1rem !important;
    }
    .program-options__list li span:first-child {
        font-size: 20px !important;
    }
    .program-options__list li span:nth-child(2) {
        font-size: 1rem !important;
    }
    .program-options__list li {
        display: flex;
        align-items: center;
        text-align: left;
    }
    .training_methodology p.mob-fs-2 {
        font-size: 1.6rem !important;
    }
    .sidebar-rounded {
        height: 140px;
    }
    .training-methodology__backg--pos {
        background-position-y: -120px;
    }
    .sticky-top div:nth-child(3) h1 {
        position: absolute;
    }
    .training-methodology__h1--pos {
        left: -30px;
        top: 30px;
    }
    
    .about-me__background--pos {
        background-position-y: -70px;
    }
    .about-me__h1--pos {
        left: -30px;
        top: 60px;
    }
    .contact-me__background--pos {
        background-position-y: -120px;
    }
    .contact-me__h1--pos {
        left: -30px;
        top: 60px;
    }
    .mobile-title-my {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    .mobile-text-my {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .career-text {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    #career .row > div:nth-child(4) {
        padding-bottom: 0 !important;
    }
    .go-home__button {
        width: 30px;
        height: 30px;
    }
    .sticky-area .times {
        -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s ease-in;
        transition: opacity 0.2s linear, -webkit-transform 0.2s ease-in;
        opacity: 0.8;
    }
    .home-instagram__icon.svg-inline--fa.fa-w-14 {
        width: 3rem;
    }
    .home-instagram__icon.svg-inline--fa {
        height: 3rem;
    }
}

@media screen and (min-width: 960px){
    .mobile-footer .row {
        flex-direction: row;
    }
    #coaching .sticky-area h1 {
        margin: 0 1.5rem 0 0;
    }
}
.row {
    margin-right: 0 !important;
}
.btn-boots-red {
    color: $white;
    font-weight: bold;
    background: #f80000;
    border-radius: 9px;
    width:14rem;
    border:0;
    &:hover,
    &:focus {
      color: $white;
      background: $red;
    }
    @include media-breakpoint-up(xl) {
      width: 25.569rem;
    }    
  } 